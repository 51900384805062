
// 体验卡页面
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { defineComponent, ref, reactive, toRefs, onBeforeMount } from "vue";
import {LeftOutlined,VerticalAlignBottomOutlined,} from "@ant-design/icons-vue";
import { getCardImage,getExperienceList, downloadImage,} from "@/api/personal/experienceCard";
import { message } from "ant-design-vue";
import { downLoadZIP } from "@/utils/downLoad";
import breadcrumb from "@/components/breadcrumb/breadcrumb.vue";
export default defineComponent({
  name: "experienceCar",
  components: {
    LeftOutlined,
    VerticalAlignBottomOutlined,
    breadcrumb,
  },
  setup() {
    // 面包屑
    const breadData = ref<Array<object>>([
      { title: "首页", path: "#/" },
      { title: "个人", path: "/index" },
      { title: "我的体验卡", path: "#/" },
    ]);
    // 查询条件
    const query = reactive({
      card_number: "",
      checked: false,
      value1: null,
      type: "",
    });

    // 卡片
    const cardData = reactive({
      list: [],
      electronicsImg: false,
      codeImg: false,
    });
    // table
    const dataSource = ref<Array<any>>([]);
    const columns = [
      {
        title: "卡号",
        dataIndex: "card_number",
        key: "card_number",
        fixed: true,
      },
      { title: "使用会员", dataIndex: "nickname", key: "nickname" },
      { title: "使用时间", dataIndex: "use_time", key: "use_time" },
      { title: "时长", dataIndex: "duration_name", key: "duration_name" },
      { title: "失效日期", dataIndex: "expires_time", key: "expires_time" },
      { title: "状态", dataIndex: "type", key: "type" },
      { title: "创建时间", dataIndex: "create_time", key: "create_time" },
    ];
    const pagination = reactive({
      page: 1,
      per_page: 10,
      total: 0,
    });

    onBeforeMount(() => {
      let role_id = localStorage.getItem("userInfo");
      role_id = JSON.parse(role_id as string);
      if ((role_id as any).role_id == 5) {
        breadData.value.splice(2, 1);
      }
      getListData();
    });

    // 展示电子图片
    const showElectronicsImg = () => {
      cardData.electronicsImg = !cardData.electronicsImg;
      cardData.codeImg = false;
      query.checked = false;
      getImageData();
    };
    // 展示二维码
    const change = (e: any) => {
      cardData.codeImg = e.target.checked;
      cardData.electronicsImg = false;
      getImageData();
    };
    //列表数据
    const getListData = (isPage?: string): void => {
      if (isPage == "page") {
        pagination.page = 1;
      }
      let data = {
        page: pagination.page,
        per_page: pagination.per_page,
        card_number: query.card_number,
        status: query.type,
        type: 2,
      };
      if (!/^[\da-z]+$/i.test(query.card_number) && query.card_number != "") {
        message.error("只能输入数字或英文");
        return;
      }
      getExperienceList(data).then((res: any) => {
        if (res.code == 200) {
          pagination.total = res.total;
          dataSource.value = res.data;
          pagination.page = res.page;
        } else {
          message.error(res.msg);
        }
      });
    };
    // 分页切换
    const tableChange = (page: any): void => {
      pagination.page = page.current;
      pagination.per_page = page.pageSize;
      getListData();
    };
    // 获取电子图片和二维码
    const getImageData = () => {
      let data = {
        page: pagination.page,
        per_page: pagination.per_page,
        type: cardData.electronicsImg ? "img" : "code",
        card_number: query.card_number,
      };
      getCardImage(data).then((res: any) => {
        console.log(res);
        if (res.code == 200) {
          cardData.list = res.data;
        }
      });
    };

    const downImage = (): void => {
      let info:any = localStorage.getItem('userInfo')
      info = JSON.parse(info)
      let url = `agent-api/api/card/downloadCardList?user_id=${info.id}`
      if(process.env.NODE_ENV === "development"){
          window.open('https://v2.diancang.site/'+ url)
      }else{
          if(location.origin == 'http://agent.huobite.club'){
              window.open('https://v2.diancang.site/'+ url)
          }else{
              window.open(location.origin +'/agentApi/'+ url)
          }
      }
      // downloadImage().then((res:any)=>{
      //   if(res.code == 200){
      //     if(process.env.NODE_ENV === "development"){
      //         window.open('https://v2.diancang.site/'+res.path)
      //     }else{
      //         if(location.origin == 'http://agent.huobite.club'){
      //             window.open('https://v2.diancang.site/'+res.path)
      //         }else{
      //             window.open(location.origin +'/agentApi/'+res.path)
      //         }
      //     }
      //   }
      // })
    };

    const selectChange = (): void => {
      getListData();
    };

    return {
      ...toRefs(query),
      ...toRefs(cardData),
      ...toRefs(pagination),
      locale,
      dataSource,
      columns,
      showElectronicsImg,
      change,
      tableChange,
      getListData,
      downImage,
      selectChange,
      breadData,
    };
  },
});
