import { service } from "@/utils/request";
import { AxiosResponse } from "axios";
import { api } from "../baseUrl";
import { setUrl } from "@/utils/common";

// 获取电子图片/二维码
export const getCardImage = (data:any): Promise<AxiosResponse<object>> =>{   
    let url = setUrl('/agent-api/api/card/experience?',data);
    return service({
        method:'get',
        url:`${api}${url}`,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}
// 获取我的体验卡/实体卡 list
export const getExperienceList = (data:object): Promise<AxiosResponse<object>> =>{   
    return service({
        method:'post',
        url:`${api}/agent-api/api/card/index`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

// 下载电子图片
// export const downloadImage = (data?:object): Promise<AxiosResponse<object>> =>{   
//     return service({
//         method:'get',
//         url:`${api}/agent-api/api/card/download`,
//         data
//     }).then(
//         (res: AxiosResponse): AxiosResponse<object> => {
//             return res;
//         }
//     );
// }

// 下载体验卡信息
export const downloadImage = (data?:object): Promise<AxiosResponse<object>> =>{   
    return service({
        method:'get',
        url:`${api}/agent-api/api/card/downloadCardList`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}